import React from "react";
import { graphql } from "gatsby";
import Seo from "components/Seo";
import Layout from "../Layout";
import Term from "./term";
import { getTermPageProps } from "helpers/getTermPageProps";

const Terms = ({ location, data }) => {
  const pathname = location.pathname;
  const pathnameMobile = location.pathname;
  const titlesEn = [
    "Definitions",
    "Privacy & Policy",
    "General terms of sales and delivery",
    "Other terms",
  ];
  const titlesRu = [
    "Определения",
    "Политика конфиденциальности",
    "Общие условия продажи и доставки",
    "Прочие положения",
  ];
  const titlesHy = [
    "Սահմանումներ",
    "Գաղտնիության քաղաքականություն",
    "Վաճառքի և առաքման ընդհանուր պայմանները",
    "Այլ դրույթներ",
  ];
  return (
    <>
      <Seo seo={{ metaTitle: "eatn’act:  terms and conditions" }} />
      {pathname === "/terms" && (
        <Layout>
          <Term
            {...getTermPageProps(data, "en")}
            titles={titlesEn}
            mobile={false}
          />
        </Layout>
      )}
      {pathnameMobile === "/terms/en" && (
        <Term
          {...getTermPageProps(data, "en")}
          titles={titlesEn}
          mobile={true}
        />
      )}
      {pathnameMobile === "/terms/ru" && (
        <Term
          {...getTermPageProps(data, "ru")}
          titles={titlesRu}
          mobile={true}
        />
      )}
      {pathnameMobile === "/terms/hy" && (
        <Term
          {...getTermPageProps(data, "hy")}
          titles={titlesHy}
          mobile={true}
        />
      )}
    </>
  );
};

export const query = graphql`
  query termPageData {
    strapiTerm {
      bgBottom {
        localFile {
          url
        }
      }
      titleBg {
        localFile {
          url
        }
      }
      title
      subtitle
      definitions {
        title
        termList {
          order
          body {
            data {
              body
            }
          }
        }
      }
      privacy {
        title
        termList {
          order
          body {
            data {
              body
            }
          }
        }
      }
      generalTerms {
        title
        termList {
          order
          body {
            data {
              body
            }
          }
        }
      }
      otherTerms {
        title
        termList {
          order
          body {
            data {
              body
            }
          }
        }
      }
    }
    strapiTermsHy {
      bgBottom {
        localFile {
          url
        }
      }
      titleBg {
        localFile {
          url
        }
      }
      title
      subtitle
      definitions {
        title
        termList {
          order
          body {
            data {
              body
            }
          }
        }
      }
      privacy {
        title
        termList {
          order
          body {
            data {
              body
            }
          }
        }
      }
      generalTerms {
        title
        termList {
          order
          body {
            data {
              body
            }
          }
        }
      }
      otherTerms {
        title
        termList {
          order
          body {
            data {
              body
            }
          }
        }
      }
    }
    strapiTermsRu {
      bgBottom {
        localFile {
          url
        }
      }
      titleBg {
        localFile {
          url
        }
      }
      title
      subtitle
      definitions {
        title
        termList {
          order
          body {
            data {
              body
            }
          }
        }
      }
      privacy {
        title
        termList {
          order
          body {
            data {
              body
            }
          }
        }
      }
      generalTerms {
        title
        termList {
          order
          body {
            data {
              body
            }
          }
        }
      }
      otherTerms {
        title
        termList {
          order
          body {
            data {
              body
            }
          }
        }
      }
    }
  }
`;
export default Terms;
