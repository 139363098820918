export const getTermPageProps = (
  { strapiTerm, strapiTermsHy, strapiTermsRu },
  lang
) => {
  const dataEn = { ...strapiTerm };
  const dataRu = { ...strapiTermsRu };
  const dataHy = { ...strapiTermsHy };
  if (lang === "ru") {
    return dataRu;
  } else if (lang === "hy") {
    return dataHy;
  } else return dataEn;
};
