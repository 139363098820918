// extracted by mini-css-extract-plugin
export var accordionTitle = "term-module--accordionTitle--03248";
export var bgBottom = "term-module--bgBottom--4bc54";
export var card = "term-module--card--1da79";
export var cardHeader = "term-module--cardHeader--7a0bf";
export var ruleTitle = "term-module--ruleTitle--5af47";
export var rules = "term-module--rules--a5d81";
export var singleRule = "term-module--singleRule--edd6d";
export var subtitle = "term-module--subtitle--0a6f3";
export var title = "term-module--title--966ef";
export var titlePart = "term-module--titlePart--23602";
export var wrapper = "term-module--wrapper--e8899";