import React, { useState } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import arrowRight from "images/arrowRight.png";
import * as styles from "./customToggle.module.scss";

const CustomToggle = ({ children, eventKey, show, setShow, index }) => {
  const [toggle, setToggle] = useState(false);
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setShow(!show);
    setToggle(!toggle);
  });

  return (
    <div className={styles.wrapper}>
      <p type="button" onClick={decoratedOnClick} className={styles.btn}>
        {children}
        <img
          src={arrowRight}
          alt="arrow"
          className={`${styles.arrow} ${
            show && index === 0 ? styles.arrowClicked : ""
          } ${toggle && index !== 0 ? styles.arrowClicked : ""}`}
        />
      </p>
      <div className={styles.border}></div>
    </div>
  );
};

export default CustomToggle;
