import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import CustomToggle from "../customToggle";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";
import { modifyTermList } from "helpers/modifyTermList";
import * as styles from "./term.module.scss";

function Term({
  title,
  subtitle,
  definitions,
  privacy,
  generalTerms,
  otherTerms,
  bgBottom,
  titleBg,
  titles,
  mobile,
}) {
  const [show, setShow] = useState(true);
  const allRules = [definitions, privacy, generalTerms, otherTerms].map(
    (rule) => {
      return rule.map(({ title, termList }, i) => {
        const modifiedTermList = modifyTermList(termList);
        const defRules = sortArrayOfObjects(
          modifiedTermList.map(({ text }, i) => {
            return (
              <ul key={i}>
                <li>{text}</li>
              </ul>
            );
          })
        );
        return (
          <div className={styles.singleRule} key={i}>
            {title !== "other" && <p className={styles.ruleTitle}>{title}</p>}
            {defRules}
          </div>
        );
      });
    }
  );
  const setAllRules = allRules.map((rules, i) => {
    return (
      <Accordion defaultActiveKey="0" key={i}>
        <Card className={`${styles.card}`}>
          <Card.Header className={styles.cardHeader}>
            <CustomToggle
              eventKey={i.toString()}
              show={i === 0 ? show : !show}
              setShow={setShow}
              index={i}
            >
              <span className={styles.accordionTitle}> {titles[i]}</span>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse
            eventKey={i.toString()}
            className={`${show && i === 0 && "show"}`}
          >
            <Card.Body>{rules}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  });
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.titlePart}
        style={
          !mobile
            ? {
                backgroundImage: "url(" + titleBg.localFile.url + ")",
              }
            : {
                paddingBottom: 0,
                marginTop: 20,
              }
        }
      >
        <p
          className={styles.title}
          style={mobile ? { color: "#099E43" } : { color: "#2b2b2b" }}
        >
          {title}
        </p>
        {!mobile && <p className={styles.subtitle}>{subtitle}</p>}
      </div>
      {!mobile && (
        <div
          className={styles.bgBottom}
          style={{
            backgroundImage: "url(" + bgBottom.localFile.url + ")",
          }}
        ></div>
      )}
      <div className={styles.rules}>{setAllRules}</div>
    </div>
  );
}

export default Term;
